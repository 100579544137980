<template>
  <div class="m-3 p-2 fontStyle w-50">
    <b-alert
      class="fixed-top"
      :show="dismissCountDown"
      dismissible
      variant="success"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p>Charger whitelisted successfully!</p>
    </b-alert>

    <b-alert
      class="fixed-top"
      :show="errorDismissCountDown"
      dismissible
      variant="danger"
      @dismissed="errorDismissCountDown = 0"
      @dismiss-count-down="errorCountDownChanged"
    >
      <p>Error whitelisting charger! {{ errorMessage }}</p>
    </b-alert>

    <b-form @submit.prevent="onSubmit">
      <b-form-group
        id="input-group-11"
        class="font-weight-bold"
        label="Power exchange area"
        label-for="input-11"
      >
        <b-form-select
          id="input-11"
          v-model="form.powerExchangeArea"
          :options="powerExchangeAreas"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-1"
        class="font-weight-bold"
        label="Charger brand"
        label-for="input-1"
      >
        <b-form-select
          id="input-1"
          v-model="selectedBrand"
          :options="chargerBrands"
          @change="getChargerModelsByBrand"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        v-if="isCustomBrandAdded"
        id="input-group-16"
        class="pl-5 font-weight-bold"
        label="Add charger brand"
        label-for="input-16"
      >
        <b-form-input
          id="input-16"
          v-model="customBrand"
          placeholder="Enter charger brand"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-2"
        class="font-weight-bold"
        label="Charger model"
        label-for="input-2"
      >
        <b-form-select
          v-if="!isCustomBrandAdded"
          id="input-2"
          v-model="selectedModel"
          :options="chargerModels"
          @change="setConnectorNumberAndChargerModel"
          required
        ></b-form-select>

        <b-form-input
          v-if="isCustomBrandAdded"
          id="input-2"
          v-model="selectedModel"
          placeholder="Enter charger model"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        v-if="isCustomModelAdded"
        id="input-group-18"
        class="pl-5 font-weight-bold"
        label="Add charger model"
        label-for="input-16"
      >
        <b-form-input
          id="input-16"
          v-model="customModel"
          placeholder="Enter charger model"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-6"
        class="font-weight-bold"
        label="Name"
        label-for="input-6"
      >
        <b-form-input
          id="input-6"
          v-model="form.name"
          placeholder="Enter charger name"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-20"
        class="font-weight-bold"
        label="Serial number"
        label-for="input-20"
      >
        <b-form-input
          id="input-20"
          v-model="form.serialNumber"
          placeholder="Enter serial number"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-19"
        class="font-weight-bold"
        label="Number of connectors"
        label-for="input-18"
      >
        <b-form-input
          :disabled="isCustomBrandAndModelFalse"
          id="input-18"
          v-model="form.connectors"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-15"
        class="font-weight-bold"
        label="Charger Tenant"
        label-for="input-15"
      >
        <b-form-select
          id="input-15"
          v-model="form.tenant"
          :options="tenants"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-3"
        class="font-weight-bold"
        label="Charging rate limit"
        label-for="input-3"
      >
        <b-form-input
          type="number"
          id="input-3"
          v-model="form.chargingRateLimit"
          placeholder="Enter charging rate limit"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-4"
        class="font-weight-bold"
        label="Charging rate unit type"
        label-for="input-4"
      >
        <b-form-select
          id="input-4"
          v-model="form.chargingRateUnitType"
          :options="chargingRateUnitTypes"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-5"
        class="font-weight-bold"
        label="Current type"
        label-for="input-5"
      >
        <b-form-select
          id="input-5"
          v-model="form.currentType"
          :options="currentTypes"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-7"
        class="font-weight-bold"
        label="Max charging power kW"
        label-for="input-7"
      >
        <b-form-input
          type="number"
          id="input-7"
          v-model="form.maxChargingPowerKw"
          placeholder="Enter max charging power kW"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-8"
        class="font-weight-bold"
        label="Max charging rate limit"
        label-for="input-8"
      >
        <b-form-input
          type="number"
          id="input-8"
          v-model="form.maxChargingRateLimit"
          placeholder="Enter max charging rate limit"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-9"
        class="font-weight-bold"
        label="Min charging rate limit"
        label-for="input-9"
      >
        <b-form-input
          type="number"
          id="input-9"
          v-model="form.minChargingRateLimit"
          placeholder="Enter min charging rate limit"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-10"
        class="font-weight-bold"
        label="Charger ownership"
        label-for="input-10"
      >
        <b-form-select
          id="input-10"
          v-model="form.ownership"
          :options="ownerships"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-13"
        class="font-weight-bold"
        label="Charger use local load balancing"
        label-for="input-13"
      >
        <b-form-select
          id="input-13"
          v-model="form.useLocalLoadBalancing"
          :options="useLocalLoadBalancings"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-14"
        class="font-weight-bold"
        label="Notes"
        label-for="input-14"
      >
        <b-form-textarea
          id="input-14"
          v-model="form.notes"
          rows="2"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-button type="submit" block variant="outline-success">Submit</b-button>
    </b-form>
  </div>
</template>

<script>
import { whitelistCharger } from "@/api/ChargerWhitelistingApi";
import { getChargerModels } from "@/api/ChargerDetailsApi";
import uniqBy from "lodash/uniqBy";

export default {
  data() {
    return {
      errorMessage: "",
      selectCustomBrand: "CUSTOM_BRAND",
      selectCustomModel: "CUSTOM_MODEL",
      selectedBrand: null,
      selectedModel: null,
      customBrand: null,
      customModel: null,
      elintaBrand: "Elinta Charge",
      elintaModels: {
        CITYCHARGE_MINI_2: "CityCharge Mini 2",
        HOMEBOX_SLIM: "HomeBox Slim",
      },

      form: {
        authListVersion: 1,
        chargerModelId: null,
        brand: null,
        connectors: null,
        model: null,
        tenant: "ENEFIT",
        chargingRateLimit: null,
        chargingRateUnitType: null,
        currentType: null,
        name: null,
        serialNumber: null,
        maxChargingPowerKw: null,
        maxChargingRateLimit: null,
        minChargingRateLimit: null,
        ownership: null,
        powerExchangeArea: "EE",
        useLocalLoadBalancing: false,
        notes: null,
        status: "ONBOARDING",
      },
      brands: [
        { text: "Select charger brand", value: null },
        { text: "Add custom brand", value: "CUSTOM_BRAND" },
      ],
      tenants: [{ text: "Select charger tenant", value: null }, "ENEFIT"],
      ownerships: [
        { text: "Select charger ownership", value: null },
        "PRIVATE",
        { value: "SEMI_PRIVATE", text: "SEMI-PRIVATE" },
      ],
      powerExchangeAreas: [
        { text: "Select power exchange areas", value: null },
        "EE",
        "LV",
        "LT",
      ],
      useLocalLoadBalancings: [false, true],
      currentTypes: [{ text: "Select current Type", value: null }, "AC", "DC"],
      chargingRateUnitTypes: [
        { text: "Select charging rate unit types", value: null },
        { text: "Ampere ", value: "A" },
        { text: "Watt", value: "W" },
      ],

      dismissSecs: 5,
      dismissCountDown: 0,
      errorDismissCountDown: 0,
      chargerModelsList: [],
      chargerBrands: [],
      chargerModels: [],
    };
  },
  computed: {
    isCustomBrandAdded() {
      return this.selectedBrand === this.selectCustomBrand;
    },
    isCustomModelAdded() {
      return this.selectedModel === this.selectCustomModel;
    },
    chargerBrand() {
      return this.selectedBrand === this.selectCustomBrand
        ? this.customBrand
        : this.selectedBrand;
    },
    isCustomBrandAndModelFalse() {
      return (
        this.isCustomBrandAdded === false && this.isCustomModelAdded === false
      );
    },
    chargerModel() {
      return this.selectedModel === this.selectCustomModel
        ? this.customModel
        : this.selectedModel;
    },
  },

  watch: {
    chargerBrand(val) {
      this.form.brand = val;

      const isElintaBrand = val === this.elintaBrand;

      this.form.maxChargingRateLimit = isElintaBrand ? 32 : null;
      this.form.minChargingRateLimit = isElintaBrand ? 6 : null;
      this.form.maxChargingPowerKw = isElintaBrand ? 22 : null;
      this.form.chargingRateUnitType = isElintaBrand ? "A" : null;
      this.form.currentType = isElintaBrand ? "AC" : null;
    },
    chargerModel(val) {
      this.form.model = val;

      const setChargingRateLimit = (limit) => {
        this.form.chargingRateLimit = limit;
      };
      if (this.form.brand === this.elintaBrand) {
        switch (val) {
          case this.elintaModels.HOMEBOX_SLIM:
            setChargingRateLimit(12);
            break;
          case this.elintaModels.CITYCHARGE_MINI_2:
            setChargingRateLimit(32);
            break;
          default:
            setChargingRateLimit(null);
        }
      }
    },
  },
  methods: {
    onSubmit() {
      const vm = this;
      vm.errorMessage = "";
      whitelistCharger(this.form)
        .then(function () {
          vm.dismissCountDown = vm.dismissSecs;
        })
        .catch(function (error) {
          if (error.response.data && error.response.data.detail) {
            vm.errorMessage = error.response.data.detail;
          }
          vm.errorDismissCountDown = vm.dismissSecs;
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    errorCountDownChanged(errorDismissCountDown) {
      this.errorDismissCountDown = errorDismissCountDown;
    },
    getChargerModelList() {
      const vm = this;
      getChargerModels().then((response) => {
        vm.chargerModelsList = response.data;
        vm.getChargerBrands();
      });
    },
    getChargerBrands() {
      const vm = this;
      const uniqueBrands = uniqBy(vm.chargerModelsList, "brand");

      vm.chargerBrands.push({ text: "Select the charger brand", value: null });

      uniqueBrands.forEach((brands) => {
        vm.chargerBrands.push(brands.brand);
      });

      vm.chargerBrands.push({
        text: "Add custom brand",
        value: "CUSTOM_BRAND",
      });
    },
    getChargerModelsByBrand() {
      const vm = this;

      const filteredModelsByBrand = vm.chargerModelsList.filter((models) => {
        return models.brand.includes(vm.selectedBrand);
      });
      vm.chargerModels.splice(0, vm.chargerModels.length);
      vm.$set(vm.chargerModels, 0, {
        text: "Select the charger model",
        value: null,
      });
      filteredModelsByBrand.forEach((models) => {
        vm.chargerModels.push(models.model);
      });
      vm.chargerModels.push({
        text: "Add custom model",
        value: "CUSTOM_MODEL",
      });
      vm.selectedModel = null;
      vm.form.chargerModelId = null;
    },
    setConnectorNumber() {
      const vm = this;

      if (vm.selectedModel === "CUSTOM_MODEL") {
        return;
      }

      vm.form.connectors = this.findChargerModelByBrandAndModel().connectors;
    },
    setChargerModelId() {
      const vm = this;

      if (vm.selectedModel === "CUSTOM_MODEL") {
        return;
      }

      const model = this.findChargerModelByBrandAndModel();
      vm.form.chargerModelId = model.id;
    },
    findChargerModelByBrandAndModel() {
      return this.chargerModelsList.find(
        (obj) =>
          obj.brand === this.selectedBrand && obj.model === this.selectedModel
      );
    },
    setConnectorNumberAndChargerModel() {
      const vm = this;
      vm.form.chargerModelId = null;
      if (vm.isCustomModelAdded) {
        return;
      }
      this.setConnectorNumber();
      this.setChargerModelId();
    },
  },
  mounted() {
    this.getChargerModelList();
  },
};
</script>
